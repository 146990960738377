import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import {Outlet} from 'react-router-dom'
import Spinner from '../common/Spinner';
import { useTranslation } from 'react-i18next';

function Layout() {

    const [loading, setLoading] = useState(true);

    const {t} = useTranslation()

    useEffect(() => {
        // callback function to call when event triggers
        const onPageLoad = () => {
          setLoading(false)
        };
    
        // Check if the page has already loaded
        if (document.readyState === 'complete') {
          onPageLoad();
        } else {
          window.addEventListener('load', onPageLoad, false);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <>
            {
                loading ? (
                    <div className='flex items-center justify-center h-[100vh]'>
                        <Spinner/>
                    </div>
                ) : (
                    <div className='flex flex-col h-[100vh]'>
                        <Navbar/>
                        <div className='mt-14'>
                            <Outlet/>
                        </div>
                        <div className='mt-auto'>
                            <Footer/>
                        </div>
                        <div className='sm:hidden fixed bottom-[7%] left-0 w-full z-40'>
                            <div className='flex justify-center'>
                                <a href='https://wa.me/77052221923' className='action-button flex justify-center text-center btn-primary text-xl shadow px-16 py-3 rounded-full bg-dark text-white uppercase'>
                                    {t('dialog.title')}
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Layout