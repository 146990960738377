import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom'
import { images } from '../utils/cases'
import arrow_blue from '../assets/icons/arrow-right-blue.svg'

const Case = () => {
    const navigate = useNavigate()
    const [next, setNext] = useState(null)
    const [prev, setPrev] = useState(null)
    const location = useLocation()
    const projects = location?.state?.projects
    const {t} = useTranslation()
    const [project, setProject] = useState({})
    const {id} = useParams()

    const tagsUrl = t('cases.tags', {returnObjects: true})

    useEffect(() => {
        setProject({})
        try {
            const foundProject = projects?.find((item) => item.title === id);
            const currentIndex = projects?.findIndex(project => project.title === id);

            // Calculate indices for the previous and next projects
            const prevIndex = currentIndex > 0 ? currentIndex - 1 : null;
            const nextIndex = currentIndex < projects?.length - 1 ? currentIndex + 1 : null;

            // Get the previous and next project titles
            const prevProject = prevIndex !== null ? projects[prevIndex].title : null;
            const nextProject = nextIndex !== null ? projects[nextIndex].title : null;
            
            if (foundProject) {
                setProject({ ...foundProject, images: images[id] });
                setNext(nextProject)
                setPrev(prevProject)
            } else {
                console.warn(`Project with title ${id} not found.`);
                navigate("/cases")
            }
        } catch (error) {
            console.error('Error finding or setting project:', error);
        }
    }, [id]);

    if (!project) {
        console.log(project)
        return (
            <div className='flex justify-center items-center w-full mt-20'>
                <div role="status">
                    <svg className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div className='container mx-auto'>
            <div className='flex flex-col space-y-20 mt-12'>
                <div className='flex flex-col space-y-6 md:space-y-10'>
                    <img src={project?.images && project?.images[0]} className='w-full rounded-[20px]' />
                    <h1 className='text-4xl md:text-6xl font-medium'>{project?.title}</h1>
                    <div className='flex justify-between items-start w-full'>
                        <p className='min-w-[100px] text-xl md:text-3xl text-gray-600'>{t('cases.done')}</p>
                        <div className='flex flex-wrap justify-end gap-2'>
                            {
                                project?.tags?.map(item => (
                                    <Link key={item} to={tagsUrl[item]} className='flex items-center justify-center border border-gray-300 rounded-full px-3 py-1 md:px-4 md:py-2 text-gray-500 font-medium text-lg md:text-xl'>
                                        {item}
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-col space-y-10 md:space-y-20'>
                    {
                        project?.paragraphs?.map((par, id) => (
                            <div key={id} data-aos="fade-up" data-aos-delay={`${500 + id*200}`} data-aos-duration="800" data-aos-once="true" className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                                <div className='flex flex-col space-y-6'>
                                    <h2 className='text-2xl md:text-4xl text-gray-600 font-medium'>{par?.title}</h2>
                                    <p className='text-lg md:text-xl'>
                                        <Trans
                                            defaults={par?.text}
                                            components={{br: <br/>}}
                                        />
                                    </p>
                                </div>
                                <img className='rounded-[20px]' src={project?.images && project?.images[id+1]} />
                            </div>

                        ))
                    }
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
                        {
                            project?.images?.slice(project?.paragraphs?.length+1).map((img, id) => (
                                <img key={id} src={img} className='rounded-[20px]' />
                            ))
                        }
                    </div>
                </div>
                <div className='flex items-center justify-between w-full gap-4'>
                    {
                        prev && (
                            <div className='flex justify-start w-full'>
                                <Link to={`/cases/${prev}`} state={{projects: projects}} className='flex items-center space-x-2 text-lg md:text-xl'>
                                    <img src={arrow_blue} className='rotate-180' width={30} />
                                    <span className='text-lightBlue'>{t('cases.prev')}</span>
                                </Link>
                            </div>
                        )
                    }
                    
                    {
                        next && (
                            <div className='flex justify-end w-full'>
                                <Link to={`/cases/${next}`} state={{projects: projects}} className='flex items-center space-x-2 text-lg md:text-xl'>
                                    <span className='text-lightBlue ml-14 sm:ml-0'>{t('cases.next')}</span>
                                    <img src={arrow_blue} width={30}/>
                                </Link>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Case