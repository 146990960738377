import React from 'react'
import logo from '../../assets/icons/logo-black.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import email from '../../assets/icons/email.svg'
import instagram from '../../assets/icons/instagram.svg'
import whatsapp from '../../assets/icons/whatsapp.svg'

function Footer() {
	const {t} = useTranslation()

	return (
		<footer className='bg-dark py-12 mt-20'>
			<div className='container mx-auto space-y-10'>
				<div className='flex flex-col md:flex-row flex-wrap justify-between gap-10 items-start'>
					<div className='flex flex-col space-y-8'>
						<img src={logo} className='w-full' />
						<Link to={"/cases"} className='flex items-center justify-center btn-primary text-bg text-lg py-2 border-white hover:bg-bg hover:text-dark'>
							{t('projects.button')}
						</Link>
					</div>
					<div className='flex flex-col space-y-4 md:space-y-6'>
						<Link to={"/services"} className='text-bg text-2xl md:text-xl'>{t('navbar.services')}</Link>
						<Link to={"/cases"} className='text-bg text-2xl md:text-xl'>{t('navbar.cases')}</Link>
						<Link to={"/vacancies"} className='text-bg text-2xl md:text-xl'>{t('navbar.vacancies')}</Link>
					</div>
					<div className='flex flex-col space-y-4 md:space-y-6'>
						<Link to={"/services#branding"} className='text-bg text-2xl md:text-xl'>{t('navbar.services-drop.branding')}</Link>
						<Link to={"/services#digup"} className='text-bg text-2xl md:text-xl'>{t('navbar.services-drop.digap')}</Link>
						<Link to={"/services#promote"} className='text-bg text-2xl md:text-xl'>{t('navbar.services-drop.promotion')}</Link>
					</div>
					<div className='flex flex-col space-y-4 lg:space-y-10'>
						<a href='mailto:hello@aigla.kz' className='text-bg text-2xl md:text-xl'>hello@aigla.kz</a>
						<div className='flex items-center space-x-6 mt-16 mx-auto'>
							<a href='mailto:hello@aigla.kz'>
								<img src={email} width={45}/>
							</a>
							<a href='https://www.instagram.com/aigla.kz?igsh=MzRlODBiNWFlZA==' target='_blank'>
								<img src={instagram} width={35}/>
							</a>
							<a href='https://wa.me/77052221923' target='_blank'>
								<img src={whatsapp} width={45}/>
							</a>
						</div>
					</div>
				</div>
				<div className='bg-lightGray w-full h-[1px]'>

				</div>
				<div className='flex items-center flex-wrap gap-6 justify-between'>
					<div className='text-lightGray text-lg uppercase'>{t('copyright')}</div>
					<div className='text-lightGray text-lg uppercase'>{t('policy')}</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer