

import ATCrossbar_1 from '../assets/cases/ATCrossbar/1.jpg';
import ATCrossbar_2 from '../assets/cases/ATCrossbar/2.jpg';
import ATCrossbar_3 from '../assets/cases/ATCrossbar/3.jpg';
import ATCrossbar_4 from '../assets/cases/ATCrossbar/4.jpg';
import ATCrossbar_5 from '../assets/cases/ATCrossbar/5.jpg';

import Balaluia_Igla_Site_Mock_Balaluia_1 from '../assets/cases/Balaluia/Igla_Site_Mock_Balaluia_1.jpg';
import Balaluia_Igla_Site_Mock_Balaluia_2 from '../assets/cases/Balaluia/Igla_Site_Mock_Balaluia_2.jpg';
import Balaluia_Igla_Site_Mock_Balaluia_3 from '../assets/cases/Balaluia/Igla_Site_Mock_Balaluia_3.jpg';
import Balaluia_Igla_Site_Mock_Balaluia_4 from '../assets/cases/Balaluia/Igla_Site_Mock_Balaluia_4.jpg';
import Balaluia_Igla_Site_Mock_Balaluia_5 from '../assets/cases/Balaluia/Igla_Site_Mock_Balaluia_5.jpg';
import Balaluia_Igla_Site_Mock_Balaluia_6 from '../assets/cases/Balaluia/Igla_Site_Mock_Balaluia_6.jpg';

import Dejalsky_Igla_Site_Mock_Dejalsky_0 from '../assets/cases/Dejalsky/Igla_Site_Mock_Dejalsky_0.jpg';
import Dejalsky_Igla_Site_Mock_Dejalsky_1 from '../assets/cases/Dejalsky/Igla_Site_Mock_Dejalsky_1.jpg';
import Dejalsky_Igla_Site_Mock_Dejalsky_2 from '../assets/cases/Dejalsky/Igla_Site_Mock_Dejalsky_2.jpg';
import Dejalsky_Igla_Site_Mock_Dejalsky_3 from '../assets/cases/Dejalsky/Igla_Site_Mock_Dejalsky_3.jpg';
import Dejalsky_Igla_Site_Mock_Dejalsky_4 from '../assets/cases/Dejalsky/Igla_Site_Mock_Dejalsky_4.jpg';
import Dejalsky_Igla_Site_Mock_Dejalsky_5 from '../assets/cases/Dejalsky/Igla_Site_Mock_Dejalsky_5.jpg';

import EcoFacade_Igla_Site_Mock_EcoFacade_1 from '../assets/cases/EcoFacade/Igla_Site_Mock_EcoFacade_1.jpg';
import EcoFacade_Igla_Site_Mock_EcoFacade_2 from '../assets/cases/EcoFacade/Igla_Site_Mock_EcoFacade_2.jpg';
import EcoFacade_Igla_Site_Mock_EcoFacade_3 from '../assets/cases/EcoFacade/Igla_Site_Mock_EcoFacade_3.jpg';
import EcoFacade_Igla_Site_Mock_EcoFacade_4 from '../assets/cases/EcoFacade/Igla_Site_Mock_EcoFacade_4.jpg';
import EcoFacade_Igla_Site_Mock_EcoFacade_5 from '../assets/cases/EcoFacade/Igla_Site_Mock_EcoFacade_5.jpg';
import EcoFacade_Igla_Site_Mock_EcoFacade_6 from '../assets/cases/EcoFacade/Igla_Site_Mock_EcoFacade_6.jpg';

import FarmMalina_Igla_Site_Mock_FM_1 from '../assets/cases/FarmMalina/Igla_Site_Mock_FM_1.jpg';
import FarmMalina_Igla_Site_Mock_FM_2 from '../assets/cases/FarmMalina/Igla_Site_Mock_FM_2.jpg';
import FarmMalina_Igla_Site_Mock_FM_3 from '../assets/cases/FarmMalina/Igla_Site_Mock_FM_3.jpg';
import FarmMalina_Igla_Site_Mock_FM_4 from '../assets/cases/FarmMalina/Igla_Site_Mock_FM_4.jpg';
import FarmMalina_fram_malina5 from '../assets/cases/FarmMalina/Igla_Site_Mock_FM_5.jpg';
import FarmMalina_fram_malina6 from '../assets/cases/FarmMalina/Igla_Site_Mock_FM_6.jpg';

import Honmil_Honmil_01 from '../assets/cases/Honmil/Honmil_01.jpg';
import Honmil_Honmil_02 from '../assets/cases/Honmil/Honmil_02.jpg';
import Honmil_Honmil_04 from '../assets/cases/Honmil/Honmil_04.jpg';
import Honmil_Honmil_05 from '../assets/cases/Honmil/Honmil_05.jpg';
import Honmil_Honmil_07 from '../assets/cases/Honmil/Honmil_07.jpg';
import Honmil_Honmil_09 from '../assets/cases/Honmil/Honmil_09.jpg';
import Honmil_Honmil_10 from '../assets/cases/Honmil/Honmil_10.jpg';

import Invesco_Igla_Site_Mock_Invesco_0 from '../assets/cases/Invesco/Igla_Site_Mock_Invesco_0.jpg';
import Invesco_Igla_Site_Mock_Invesco_2 from '../assets/cases/Invesco/Igla_Site_Mock_Invesco_2.jpg';
import Invesco_Igla_Site_Mock_Invesco_3 from '../assets/cases/Invesco/Igla_Site_Mock_Invesco_3.jpg';
import Invesco_Igla_Site_Mock_Invesco_4 from '../assets/cases/Invesco/Igla_Site_Mock_Invesco_4.jpg';
import Invesco_Igla_Site_Mock_Invesco_5 from '../assets/cases/Invesco/Igla_Site_Mock_Invesco_5.jpg';

import KaspiLogistics_Igla_Site_Mock_KL_1 from '../assets/cases/KaspiLogistics/Igla_Site_Mock_KL_1.jpg';
import KaspiLogistics_Igla_Site_Mock_KL_3 from '../assets/cases/KaspiLogistics/Igla_Site_Mock_KL_3.jpg';
import KaspiLogistics_Igla_Site_Mock_KL_4 from '../assets/cases/KaspiLogistics/Igla_Site_Mock_KL_4.jpg';
import KaspiLogistics_Igla_Site_Mock_KL_5 from '../assets/cases/KaspiLogistics/Igla_Site_Mock_KL_5.jpg';
import KaspiLogistics_Igla_Site_Mock_KL_6 from '../assets/cases/KaspiLogistics/Igla_Site_Mock_KL_6.jpg';

import LiderStroy_Igla_Site_Mock_LSP_0 from '../assets/cases/LiderStroy/Igla_Site_Mock_LSP_0.jpg';
import LiderStroy_Igla_Site_Mock_LSP_1 from '../assets/cases/LiderStroy/Igla_Site_Mock_LSP_1.jpg';
import LiderStroy_Igla_Site_Mock_LSP_2 from '../assets/cases/LiderStroy/Igla_Site_Mock_LSP_2.jpg';
import LiderStroy_Igla_Site_Mock_LSP_3 from '../assets/cases/LiderStroy/Igla_Site_Mock_LSP_3.jpg';
import LiderStroy_Igla_Site_Mock_LSP_4 from '../assets/cases/LiderStroy/Igla_Site_Mock_LSP_4.jpg';
import LiderStroy_Igla_Site_Mock_LSP_5 from '../assets/cases/LiderStroy/Igla_Site_Mock_LSP_5.jpg';

import MTS_Igla_Site_Mock_MTS_1 from '../assets/cases/MTS/Igla_Site_Mock_MTS_1.jpg';
import MTS_Igla_Site_Mock_MTS_4 from '../assets/cases/MTS/Igla_Site_Mock_MTS_4.jpg';
import MTS_Igla_Site_Mock_MTS_5 from '../assets/cases/MTS/Igla_Site_Mock_MTS_5.jpg';
import MTS_Igla_Site_Mock_MTS_6 from '../assets/cases/MTS/Igla_Site_Mock_MTS_6.jpg';
import MTS_MTS_06 from '../assets/cases/MTS/MTS_06.jpg';

import Oxymaster_Igla_Site_Mock_Oxymaster_1 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_1.jpg';
import Oxymaster_Igla_Site_Mock_Oxymaster_3 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_3.jpg';
import Oxymaster_Igla_Site_Mock_Oxymaster_4 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_4.jpg';
import Oxymaster_Igla_Site_Mock_Oxymaster_5 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_5.jpg';
import Oxymaster_Igla_Site_Mock_Oxymaster_6 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_6.jpg';
import Oxymaster_Igla_Site_Mock_Oxymaster_7 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_7.jpg';
import Oxymaster_Igla_Site_Mock_Oxymaster_8 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_8.png';
import Oxymaster_Igla_Site_Mock_Oxymaster_9 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_9.png';
import Oxymaster_Igla_Site_Mock_Oxymaster_10 from '../assets/cases/Oxymaster/Igla_Site_Mock_Oxymaster_10.png';


import Qroqus_Cosmetic_Packaging_Mockup from '../assets/cases/Qroqus/Cosmetic_Packaging_Mockup.jpg';
import Qroqus_Qroqus_02 from '../assets/cases/Qroqus/Qroqus_02.jpg';
import Qroqus_Qroqus_03 from '../assets/cases/Qroqus/Qroqus_03.jpg';
import Qroqus_Qroqus_08 from '../assets/cases/Qroqus/Qroqus_08.jpg';
import Qroqus_Qroqus_09 from '../assets/cases/Qroqus/Qroqus_09.jpg';

import Red_Dragon_Igla_Site_Mock_RedDragon_1 from '../assets/cases/Red_Dragon/Igla_Site_Mock_RedDragon_1.jpg';
import Red_Dragon_Igla_Site_Mock_RedDragon_2 from '../assets/cases/Red_Dragon/Igla_Site_Mock_RedDragon_2.jpg';
import Red_Dragon_Igla_Site_Mock_RedDragon_3 from '../assets/cases/Red_Dragon/Igla_Site_Mock_RedDragon_3.jpg';
import Red_Dragon_Igla_Site_Mock_RedDragon_4 from '../assets/cases/Red_Dragon/Igla_Site_Mock_RedDragon_4.jpg';
import Red_Dragon_Igla_Site_Mock_RedDragon_5 from '../assets/cases/Red_Dragon/Igla_Site_Mock_RedDragon_5.jpg';
import Red_Dragon_Igla_Site_Mock_RedDragon_6 from '../assets/cases/Red_Dragon/Igla_Site_Mock_RedDragon_6.jpg';
import Red_Dragon_Igla_Site_Mock_RedDragon_7 from '../assets/cases/Red_Dragon/Igla_Site_Mock_RedDragon_7.jpg';

import Saktaz_Igla_Site_Mock_Saktaz_1 from '../assets/cases/Saktaz/Igla_Site_Mock_Saktaz_1.jpg';
import Saktaz_Igla_Site_Mock_Saktaz_2 from '../assets/cases/Saktaz/Igla_Site_Mock_Saktaz_2.jpg';
import Saktaz_Igla_Site_Mock_Saktaz_3 from '../assets/cases/Saktaz/Igla_Site_Mock_Saktaz_3.jpg';
import Saktaz_Igla_Site_Mock_Saktaz_4 from '../assets/cases/Saktaz/Igla_Site_Mock_Saktaz_4.jpg';
import Saktaz_Igla_Site_Mock_Saktaz_5 from '../assets/cases/Saktaz/Igla_Site_Mock_Saktaz_5.jpg';
import Saktaz_Igla_Site_Mock_Saktaz_6 from '../assets/cases/Saktaz/Igla_Site_Mock_Saktaz_6.jpg';

import SmartConstruction_Igla_Site_Mock_SC_0 from '../assets/cases/SmartConstruction/Igla_Site_Mock_SC_0.jpg';
import SmartConstruction_Igla_Site_Mock_SC_1 from '../assets/cases/SmartConstruction/Igla_Site_Mock_SC_1.jpg';
import SmartConstruction_Igla_Site_Mock_SC_2 from '../assets/cases/SmartConstruction/Igla_Site_Mock_SC_2.jpg';
import SmartConstruction_Igla_Site_Mock_SC_3 from '../assets/cases/SmartConstruction/Igla_Site_Mock_SC_3.jpg';
import SmartConstruction_Igla_Site_Mock_SC_4 from '../assets/cases/SmartConstruction/Igla_Site_Mock_SC_4.jpg';
import SmartConstruction_Igla_Site_Mock_SC_5 from '../assets/cases/SmartConstruction/Igla_Site_Mock_SC_5.jpg';
import SmartConstruction_Igla_Site_Mock_SC_6 from '../assets/cases/SmartConstruction/Igla_Site_Mock_SC_6.jpg';

import Stagastroy_Igla_Site_Mock_StagaC_0 from '../assets/cases/Stagastroy/Igla_Site_Mock_StagaC_0.jpg';
import Stagastroy_Igla_Site_Mock_StagaC_1 from '../assets/cases/Stagastroy/Igla_Site_Mock_StagaC_1.jpg';
import Stagastroy_Igla_Site_Mock_StagaC_2 from '../assets/cases/Stagastroy/Igla_Site_Mock_StagaC_2.jpg';
import Stagastroy_Igla_Site_Mock_StagaC_3 from '../assets/cases/Stagastroy/Igla_Site_Mock_StagaC_3.jpg';
import Stagastroy_Igla_Site_Mock_StagaC_4 from '../assets/cases/Stagastroy/Igla_Site_Mock_StagaC_4.jpg';
import Stagastroy_Igla_Site_Mock_StagaC_5 from '../assets/cases/Stagastroy/Igla_Site_Mock_StagaC_5.png';
import Stagastroy_Igla_Site_Mock_StagaC_6 from '../assets/cases/Stagastroy/Igla_Site_Mock_StagaC_6.png';
import Stagastroy_Igla_Site_Mock_StagaC_7 from '../assets/cases/Stagastroy/Igla_Site_Mock_StagaC_7.png';

import TolkoPizza_Box from '../assets/cases/TolkoPizza/Box.jpg';
import TolkoPizza_Branding from '../assets/cases/TolkoPizza/Branding.jpg';
import TolkoPizza_Tolko1 from '../assets/cases/TolkoPizza/Tolko1.jpg';
import TolkoPizza_Tolko2 from '../assets/cases/TolkoPizza/Tolko2.jpg';
import TolkoPizza_Tolko3 from '../assets/cases/TolkoPizza/Tolko3.jpg';

import Treagra_Igla_Site_Mock_Treagra_2 from '../assets/cases/Treagra/Igla_Site_Mock_Treagra_2.jpg';
import Treagra_Igla_Site_Mock_Treagra_3 from '../assets/cases/Treagra/Igla_Site_Mock_Treagra_3.jpg';
import Treagra_Igla_Site_Mock_Treagra_4 from '../assets/cases/Treagra/Igla_Site_Mock_Treagra_4.jpg';
import Treagra_Igla_Site_Mock_Treagra_5 from '../assets/cases/Treagra/Igla_Site_Mock_Treagra_5.jpg';
import Treagra_Igla_Site_Mock_Treagra_6 from '../assets/cases/Treagra/Igla_Site_Mock_Treagra_6.jpg';

import Troika_Igla_Site_Mock_Troika_1 from '../assets/cases/Troika/Igla_Site_Mock_Troika_1.jpg';
import Troika_Igla_Site_Mock_Troika_2 from '../assets/cases/Troika/Igla_Site_Mock_Troika_2.jpg';
import Troika_Igla_Site_Mock_Troika_3 from '../assets/cases/Troika/Igla_Site_Mock_Troika_3.jpg';
import Troika_Igla_Site_Mock_Troika_4 from '../assets/cases/Troika/Igla_Site_Mock_Troika_4.jpg';
import Troika_Igla_Site_Mock_Troika_5 from '../assets/cases/Troika/Igla_Site_Mock_Troika_5.jpg';
import Troika_Igla_Site_Mock_Troika_6 from '../assets/cases/Troika/Igla_Site_Mock_Troika_6.jpg';


import WildTicket_Igla_Site_Mock_WT_0 from '../assets/cases/WildTicket/Igla_Site_Mock_WT_0.jpg';
import WildTicket_Igla_Site_Mock_WT_1 from '../assets/cases/WildTicket/Igla_Site_Mock_WT_1.jpg';
import WildTicket_Igla_Site_Mock_WT_2 from '../assets/cases/WildTicket/Igla_Site_Mock_WT_2.jpg';
import WildTicket_Igla_Site_Mock_WT_3 from '../assets/cases/WildTicket/Igla_Site_Mock_WT_3.jpg';
import WildTicket_Igla_Site_Mock_WT_4 from '../assets/cases/WildTicket/Igla_Site_Mock_WT_4.jpg';
import WildTicket_Igla_Site_Mock_WT_5 from '../assets/cases/WildTicket/Igla_Site_Mock_WT_5.jpg';

import Zhamakayev_Igla_Site_Mock_Zhamakayev_1 from '../assets/cases/Zhamakayev/Igla_Site_Mock_Zhamakayev_1.jpg';
import Zhamakayev_Igla_Site_Mock_Zhamakayev_2 from '../assets/cases/Zhamakayev/Igla_Site_Mock_Zhamakayev_2.jpg';
import Zhamakayev_Igla_Site_Mock_Zhamakayev_3 from '../assets/cases/Zhamakayev/Igla_Site_Mock_Zhamakayev_3.jpg';
import Zhamakayev_Igla_Site_Mock_Zhamakayev_4 from '../assets/cases/Zhamakayev/Igla_Site_Mock_Zhamakayev_4.jpg';
import Zhamakayev_Igla_Site_Mock_Zhamakayev_5 from '../assets/cases/Zhamakayev/Igla_Site_Mock_Zhamakayev_5.jpg';
import Zhamakayev_Igla_Site_Mock_Zhamakayev_6 from '../assets/cases/Zhamakayev/Igla_Site_Mock_Zhamakayev_6.jpg';
import Zhamakayev_Igla_Site_Mock_Zhamakayev_7 from '../assets/cases/Zhamakayev/Igla_Site_Mock_Zhamakayev_7.jpg';

import Zhebe_Igla_Site_Mock_Zhebe_1 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_1.jpg';
import Zhebe_Igla_Site_Mock_Zhebe_2 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_2.jpg';
import Zhebe_Igla_Site_Mock_Zhebe_3 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_3.jpg';
import Zhebe_Igla_Site_Mock_Zhebe_4 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_4.jpg';
import Zhebe_Igla_Site_Mock_Zhebe_5 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_5.jpg';
import Zhebe_Igla_Site_Mock_Zhebe_6 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_6.jpg';
import Zhebe_Igla_Site_Mock_Zhebe_7 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_7.png';
import Zhebe_Igla_Site_Mock_Zhebe_8 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_8.png';
import Zhebe_Igla_Site_Mock_Zhebe_9 from '../assets/cases/Zhebe/Igla_Site_Mock_Zhebe_9.png';

import rofas_Rofas01 from '../assets/cases/rofas/Rofas01.jpg';
import rofas_Rofas02 from '../assets/cases/rofas/Rofas02.jpg';
import rofas_Rofas03 from '../assets/cases/rofas/Rofas03.jpg';
import rofas_Rofas04 from '../assets/cases/rofas/Rofas04.jpg';
import rofas_Rofas05 from '../assets/cases/rofas/Rofas05.jpg';
import rofas_Rofas06 from '../assets/cases/rofas/Rofas06.jpg';
import rofas_Rofas10 from '../assets/cases/rofas/Rofas10.jpg';
import rofas_Rofas11 from '../assets/cases/rofas/Rofas11.png';
import rofas_Rofas12 from '../assets/cases/rofas/Rofas12.png';
import rofas_Rofas13 from '../assets/cases/rofas/Rofas13.png';

import Банзай_Банзай01 from '../assets/cases/Банзай/Банзай01.jpg';
import Банзай_Банзай03 from '../assets/cases/Банзай/Банзай03.jpg';
import Банзай_Банзай05 from '../assets/cases/Банзай/Банзай05.jpg';
import Банзай_Банзай06 from '../assets/cases/Банзай/Банзай06.jpg';
import Банзай_Банзай08 from '../assets/cases/Банзай/Банзай08.jpg';
import Банзай_Банзай10 from '../assets/cases/Банзай/Банзай10.jpg';

export const ATCrossbarImages = [ATCrossbar_1, ATCrossbar_2, ATCrossbar_3, ATCrossbar_4, ATCrossbar_5];
export const BanzaiImages = [Банзай_Банзай01, Банзай_Банзай03, Банзай_Банзай05, Банзай_Банзай06, Банзай_Банзай08, Банзай_Банзай10];
export const rofasImages = [rofas_Rofas01, rofas_Rofas02, rofas_Rofas03, rofas_Rofas04, rofas_Rofas05, rofas_Rofas06, rofas_Rofas10, rofas_Rofas11, rofas_Rofas12, rofas_Rofas13];
export const ZhebeImages = [Zhebe_Igla_Site_Mock_Zhebe_1, Zhebe_Igla_Site_Mock_Zhebe_2, Zhebe_Igla_Site_Mock_Zhebe_3, Zhebe_Igla_Site_Mock_Zhebe_4, Zhebe_Igla_Site_Mock_Zhebe_5, Zhebe_Igla_Site_Mock_Zhebe_6, Zhebe_Igla_Site_Mock_Zhebe_7, Zhebe_Igla_Site_Mock_Zhebe_8, Zhebe_Igla_Site_Mock_Zhebe_9];
export const ZhamakayevImages = [Zhamakayev_Igla_Site_Mock_Zhamakayev_7, Zhamakayev_Igla_Site_Mock_Zhamakayev_1, Zhamakayev_Igla_Site_Mock_Zhamakayev_2, Zhamakayev_Igla_Site_Mock_Zhamakayev_3, Zhamakayev_Igla_Site_Mock_Zhamakayev_4, Zhamakayev_Igla_Site_Mock_Zhamakayev_5, Zhamakayev_Igla_Site_Mock_Zhamakayev_6];
export const WildTicketImages = [WildTicket_Igla_Site_Mock_WT_0, WildTicket_Igla_Site_Mock_WT_1, WildTicket_Igla_Site_Mock_WT_2, WildTicket_Igla_Site_Mock_WT_3, WildTicket_Igla_Site_Mock_WT_4, WildTicket_Igla_Site_Mock_WT_5];
export const TroikaImages = [Troika_Igla_Site_Mock_Troika_1, Troika_Igla_Site_Mock_Troika_2, Troika_Igla_Site_Mock_Troika_3, Troika_Igla_Site_Mock_Troika_4, Troika_Igla_Site_Mock_Troika_5, Troika_Igla_Site_Mock_Troika_6];
export const TreagraImages = [Treagra_Igla_Site_Mock_Treagra_2, Treagra_Igla_Site_Mock_Treagra_3, Treagra_Igla_Site_Mock_Treagra_4, Treagra_Igla_Site_Mock_Treagra_5, Treagra_Igla_Site_Mock_Treagra_6];
export const TolkoPizzaImages = [TolkoPizza_Box, TolkoPizza_Branding, TolkoPizza_Tolko1, TolkoPizza_Tolko2, TolkoPizza_Tolko3];
export const StagastroyImages = [Stagastroy_Igla_Site_Mock_StagaC_0, Stagastroy_Igla_Site_Mock_StagaC_1, Stagastroy_Igla_Site_Mock_StagaC_2, Stagastroy_Igla_Site_Mock_StagaC_3, Stagastroy_Igla_Site_Mock_StagaC_4, Stagastroy_Igla_Site_Mock_StagaC_5, Stagastroy_Igla_Site_Mock_StagaC_6, Stagastroy_Igla_Site_Mock_StagaC_7];
export const SmartConstructionImages = [SmartConstruction_Igla_Site_Mock_SC_0, SmartConstruction_Igla_Site_Mock_SC_1, SmartConstruction_Igla_Site_Mock_SC_2, SmartConstruction_Igla_Site_Mock_SC_3, SmartConstruction_Igla_Site_Mock_SC_4, SmartConstruction_Igla_Site_Mock_SC_5, SmartConstruction_Igla_Site_Mock_SC_6];
export const SaktazImages = [Saktaz_Igla_Site_Mock_Saktaz_1, Saktaz_Igla_Site_Mock_Saktaz_2, Saktaz_Igla_Site_Mock_Saktaz_3, Saktaz_Igla_Site_Mock_Saktaz_4, Saktaz_Igla_Site_Mock_Saktaz_5, Saktaz_Igla_Site_Mock_Saktaz_6];
export const Red_DragonImages = [Red_Dragon_Igla_Site_Mock_RedDragon_1, Red_Dragon_Igla_Site_Mock_RedDragon_2, Red_Dragon_Igla_Site_Mock_RedDragon_3, Red_Dragon_Igla_Site_Mock_RedDragon_4, Red_Dragon_Igla_Site_Mock_RedDragon_5, Red_Dragon_Igla_Site_Mock_RedDragon_6, Red_Dragon_Igla_Site_Mock_RedDragon_7];
export const QroqusImages = [Qroqus_Cosmetic_Packaging_Mockup, Qroqus_Qroqus_02, Qroqus_Qroqus_03, Qroqus_Qroqus_08, Qroqus_Qroqus_09];
export const OxymasterImages = [Oxymaster_Igla_Site_Mock_Oxymaster_1, Oxymaster_Igla_Site_Mock_Oxymaster_3, Oxymaster_Igla_Site_Mock_Oxymaster_4, Oxymaster_Igla_Site_Mock_Oxymaster_5, Oxymaster_Igla_Site_Mock_Oxymaster_6, Oxymaster_Igla_Site_Mock_Oxymaster_7, Oxymaster_Igla_Site_Mock_Oxymaster_8, Oxymaster_Igla_Site_Mock_Oxymaster_9, Oxymaster_Igla_Site_Mock_Oxymaster_10];
export const MTSImages = [MTS_Igla_Site_Mock_MTS_1, MTS_Igla_Site_Mock_MTS_4, MTS_Igla_Site_Mock_MTS_5, MTS_Igla_Site_Mock_MTS_6, MTS_MTS_06];
export const BalaluiaImages = [Balaluia_Igla_Site_Mock_Balaluia_1, Balaluia_Igla_Site_Mock_Balaluia_2, Balaluia_Igla_Site_Mock_Balaluia_3, Balaluia_Igla_Site_Mock_Balaluia_4, Balaluia_Igla_Site_Mock_Balaluia_5, Balaluia_Igla_Site_Mock_Balaluia_6];
export const LiderStroyImages = [LiderStroy_Igla_Site_Mock_LSP_0, LiderStroy_Igla_Site_Mock_LSP_1, LiderStroy_Igla_Site_Mock_LSP_2, LiderStroy_Igla_Site_Mock_LSP_3, LiderStroy_Igla_Site_Mock_LSP_4, LiderStroy_Igla_Site_Mock_LSP_5];
export const KaspiLogisticsImages = [KaspiLogistics_Igla_Site_Mock_KL_1, KaspiLogistics_Igla_Site_Mock_KL_3, KaspiLogistics_Igla_Site_Mock_KL_4, KaspiLogistics_Igla_Site_Mock_KL_5, KaspiLogistics_Igla_Site_Mock_KL_6];
export const InvescoImages = [Invesco_Igla_Site_Mock_Invesco_0, Invesco_Igla_Site_Mock_Invesco_2, Invesco_Igla_Site_Mock_Invesco_3, Invesco_Igla_Site_Mock_Invesco_4, Invesco_Igla_Site_Mock_Invesco_5];
export const HonmilImages = [Honmil_Honmil_01, Honmil_Honmil_02, Honmil_Honmil_04, Honmil_Honmil_05, Honmil_Honmil_07, Honmil_Honmil_09, Honmil_Honmil_10];
export const FarmMalinaImages = [FarmMalina_Igla_Site_Mock_FM_1, FarmMalina_Igla_Site_Mock_FM_2, FarmMalina_Igla_Site_Mock_FM_3, FarmMalina_Igla_Site_Mock_FM_4, FarmMalina_fram_malina5, FarmMalina_fram_malina6];
export const EcoFacadeImages = [EcoFacade_Igla_Site_Mock_EcoFacade_1, EcoFacade_Igla_Site_Mock_EcoFacade_2, EcoFacade_Igla_Site_Mock_EcoFacade_3, EcoFacade_Igla_Site_Mock_EcoFacade_4, EcoFacade_Igla_Site_Mock_EcoFacade_5, EcoFacade_Igla_Site_Mock_EcoFacade_6];
export const DejalskyImages = [Dejalsky_Igla_Site_Mock_Dejalsky_0, Dejalsky_Igla_Site_Mock_Dejalsky_1, Dejalsky_Igla_Site_Mock_Dejalsky_2, Dejalsky_Igla_Site_Mock_Dejalsky_3, Dejalsky_Igla_Site_Mock_Dejalsky_4, Dejalsky_Igla_Site_Mock_Dejalsky_5];

export const images = {
    'Farmovaya малина': FarmMalinaImages,   'Rofas': rofasImages,
    'Red Dragon': Red_DragonImages,         'Honmil': HonmilImages,
    'Zhamakayev Club House': ZhamakayevImages,           'Saktaz': SaktazImages,
    'Stagastroy': StagastroyImages,         'Banzai sushi': BanzaiImages,
    'Kaspi Logistics': KaspiLogisticsImages,    'Тройка LOGISTICS': TroikaImages,
    'Eco Facade': EcoFacadeImages,         'MTS Company': MTSImages,
    'OXY Master': OxymasterImages,         'Только пицца': TolkoPizzaImages,
    'Qroqus skin care': QroqusImages,   'Treagra safety': TreagraImages,
    'Zhebe Logistics': ZhebeImages,    'At.Crossbar': ATCrossbarImages,
    'Balaluia': BalaluiaImages,            'Дежалъский продукт': DejalskyImages,
    'Лидер Строй Проект': LiderStroyImages,
    'Smart Construction': SmartConstructionImages, 'Wild Ticket Kazakhstan': WildTicketImages
}