import React from 'react'
import logo from '../../assets/icons/logo-small.svg'

const Spinner = () => {
    

    return (
        <>
            <img className='fade' src={logo}/>
        </>
    )
}

export default Spinner